import {actionMap, typeTextMap, capitalize, formatAreaName, getPriceRangeString, getSizeRangeString, getBedroomType} from "../SeoContentUtils/commonUtils";


/** Generates a meta description for property pages based on SEO parameters. **/

export const generateCommercialMetaDescription = (seoParams) => {
    const { pType, areas, bedrooms, type, minsize, maxsize, price } = seoParams;
    const action = actionMap[pType] || "";
    const area = areas ? formatAreaName(areas) : 'Dubai';
    const propertyType = type ? capitalize(type) : '';

    let metaDescription ='';
  
    if(area === 'Dubai') {
        metaDescription = `Discover our wide range of commercial properties ${action} in ${area}. Contact The Pearl Gates commerical property experts today to arrange a viewing for your dream property.`;
    }else {
        metaDescription = `Explore our latest collection of commercial properties ${action} in ${area} with the help of The Pearl Gates real estate agents in ${area}. Contact us for more informations.`
    }
     
  
    // [price-range]+[properties-type]
    if ((price && (price.min !== '' || price.max !== '')) && type) {
        const priceRange = getPriceRangeString(price);
        metaDescription = `Check out our latest collection ${propertyType} ${action} in ${area} ${priceRange}. Reach out The Pearl Gates today, and let us assist you in finding the perfect property.`;
    }
    // [price-range] 
    else if (price && (price.min !== '' || price.max !== '')) {
        const priceRange = getPriceRangeString(price);
        metaDescription = `Explore our wide range of commercial properties ${action} in ${area} ${priceRange}. Reach out to The Pearl Gates commercial property experts to discover the perfect properties ${action} in ${area}`;
    }
  
    // [properties-type]
    else if (type) {
        metaDescription = `Are you in search of ${propertyType} ${action} in ${area}? The Pearl Gates Dubai real estate brokers are here to assist you. Contact us now! `;
    }
  
    return metaDescription;
  };
  
  /** Generates introductory copy for property pages based on SEO parameters. **/

  export const generateCommercialIntroCopy = (seoParams) => {
    const { pType, areas, bedrooms, type, minsize, maxsize, price } = seoParams;
    const action = actionMap[pType] || "";
    const area = areas ? capitalize(areas) : 'Dubai';
    const saleTypeText = typeTextMap[`commercial-${pType}`] || ""; 
    const propertyType = type ? capitalize(type) : '';
  
    let introCopy = ""; // Initialize introCopy to empty string
    let introClosingCopy = ` ${saleTypeText} commercial property in ${area}, contact The Pearl Gates real estate agents in Dubai`;
  
    // [price-range]+[properties-type]
    if ((price && (price.min !== '' || price.max !== '')) && type) {
      const priceRange = getPriceRangeString(price);
      introCopy = ` ${propertyType} ${action} in ${area} ${priceRange}`;
    }  
    // [price-range] 
    else if (price && (price.min !== '' || price.max !== '')) {
      const priceRange = getPriceRangeString(price); 
      introCopy = ` Properties ${action} in ${area} ${priceRange}`;    
    }

    // [properties-type]
    else if (type) {
      introCopy = ` ${propertyType} ${action} in ${area}`;
      introClosingCopy = ` ${propertyType} ${action} in ${area}, contact The Pearl Gates real estate agents in Dubai`;
    }
    //default
    else {
      introCopy = ` commercial properties ${action} in ${area}`;
    }
  
    introCopy += ` with The Pearl Gates. Our expert <a href="${process.env.GATSBY_SITE_URL}">real estate agents in Dubai</a> are ready to assist you. Use the above filter to refine your search results, or for more information about`;  

    // Add the common introductory phrase if introCopy is not empty
    if (introCopy !== "") {
      introCopy = `Explore our latest collection of ${introCopy} ${introClosingCopy}`;
    }
  
    return introCopy;
};